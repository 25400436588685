import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbar,
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid";
import Tippy from "@tippyjs/react";
import columns from "./TableColumns";
import instance from "../../redux/actions/AxiosInstance/authenticated";
import { convertMongooseDateTimeToDDMMYYYYTime } from "../utils";
import AddToListModal from "./AddToListModal";

const Table = ({
  data,
  loading,
  fetchData,
  totalRows,
  task_id,
  task_name,
  created_at,
  showExtra,
}) => {
  const [paginationModal, setPaginationModal] = useState({
    page: 0,
    pageSize: 10,
  });
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState(null);
  const [downloadingResults, setDownloadingResults] = useState(false);
  const [addToListModal, setAddToListModal] = useState(false);

  const [visibility, setVisibility] = useState({});

  let preparedCoumns = [...columns];
  // console.log(data[0], "data[0]");

  console.log(showExtra, "showExtra");
  if (showExtra) {
    let extraFields = {};
    if (data && data.length > 0) {
      for (let row of data) {
        if (row.extraFields) {
          extraFields = row.extraFields;
        }
      }
    }
    for (let key in extraFields) {
      preparedCoumns.push({
        field: `extra ${key}`,
        headerName: key.charAt(0).toUpperCase() + key.slice(1),
        type: "string",
        width: 120,
        filterable: true,
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === "contains"
        ),
        renderCell: (params) => {
          return (
            <div className="font-[400] font-Inter text-[13.5px] text-[#000000de] truncate">
              {params.row?.extraFields?.[key] || "-"}
            </div>
          );
        },
      });
    }
  }

  async function downloadResults(is_current) {
    try {
      setDownloadingResults(true);
      const response = await instance.get("/scrapper/download-task-result", {
        params: {
          is_current: is_current,
          sortModel: sortModel,
          filterModel: filterModel,
          paginationModal: paginationModal,
          visibility: visibility,
          task_id: task_id,
        },
      });

      const formattedDateTime =
        convertMongooseDateTimeToDDMMYYYYTime(created_at);

      let filename = `result-${task_name}-${formattedDateTime}.csv`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", filename);
      link.setAttribute("hidden", true);
      document.body.appendChild(link);
      link.click();
      setDownloadingResults(false);
      // console.log(response);
    } catch (e) {
      console.log(e);
      setDownloadingResults(false);
    }
  }

  useEffect(() => {
    var tableParent = document.getElementById("entableparent");
    if (tableParent) {
      var table = document.getElementById("enrichmentDetailsTable");

      // console.log(tableParent.offsetWidth);
      //set the maxWith of the table to the width of the parent
      table.style.maxWidth = tableParent.offsetWidth - 50 + "px";
    }
  }, []);

  useEffect(() => {
    if (filterModel != null) {
      let timeout = setTimeout(() => {
        fetchData(paginationModal, sortModel, filterModel);
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [filterModel]);

  useEffect(() => {
    fetchData(paginationModal, sortModel, filterModel);
  }, [paginationModal, sortModel]);

  return (
    <>
      <div className="flex item-center justify-between">
        <div className="">
          <p className="text-lg text-secondary font-semibold text-Inter">
            Run Info
          </p>
          <p className="text-sm text-secondarySupport text-Inter">
            Information about the uploaded CSV.
          </p>
        </div>
        <div className="flex item-center gap-x-4">
          <button
            onClick={() => {
              setAddToListModal(true);
            }}
            disabled={loading}
            className={`px-6 mt-2 py-1.5 text-sm text-Inter border-gray-500 border-2 text-gray-500 bg-white font-[500] rounded-lg ${
              loading && `bg-opacity-50`
            }`}
          >
            Add to List
          </button>
          <button
            onClick={() => {
              downloadResults(true);
            }}
            className={`px-6 mt-2 py-1.5 text-sm text-Inter bg-primary text-white font-[500] rounded-lg border-[1px] border-primary ${
              loading && `bg-opacity-50`
            }`}
          >
            Download
          </button>
        </div>
      </div>
      <div
        id="enrichmentDetailsTable"
        style={{ height: 560 }}
        className=" mt-5"
      >
        <DataGrid
          sx={{
            overflowX: "scroll",
            border: 0,
            "& .css-17jjc08-MuiDataGrid-footerContainer": {
              borderTop: 0,
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "500",
              fontSize: "13px",
              lineHeight: "18px",
              fontFamily: "Inter",
            },
            "& .MuiSvgIcon-root": {
              fontSize: "1.15rem",
            },
            "& .MuiDataGrid-iconSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-cell": {
              whiteSpace: "pre-wrap !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-selectedRowCount": {
              opacity: 0,
            },
          }}
          sortingMode="server"
          rows={loading ? [] : data}
          columns={preparedCoumns}
          getRowId={(row) => row._id}
          filterMode="server"
          onFilterModelChange={(model) => {
            setFilterModel(model);
          }}
          onSortModelChange={(model) => {
            // console.log(model);
            setSortModel(model);
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
          onPaginationModelChange={(model) => {
            setPaginationModal(model);
          }}
          paginationModel={paginationModal}
          rowCount={totalRows}
          loading={loading}
          slots={{
            toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
            },
          }}
          columnVisibilityModel={visibility}
          onColumnVisibilityModelChange={(model) => {
            setVisibility(model);
          }}
        ></DataGrid>
      </div>
      {addToListModal && (
        <AddToListModal
          currentRoute={task_id}
          setAddToListModal={setAddToListModal}
        ></AddToListModal>
      )}
    </>
  );
};

export default Table;
